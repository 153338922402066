import { useHistory } from "react-router-dom";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

import "./Navigationbar.css";

const NavigationbarSuperAdmin = ({ handleDrawerClose }: any) => {
  const history = useHistory();
  const changeRoute = (route: any) => {
    handleDrawerClose();
    history.push(route);
  };
  
  return (
    <>
      <Divider />
      <ListItem button onClick={() => changeRoute("/bedrijf")}>
        <ListItemIcon>
          <BusinessRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Bedrijf's Data" />
      </ListItem>
      <Divider />
      <ListItem button onClick={() => changeRoute("/gebruikersbeheer")}>
        <ListItemIcon>
          <ManageAccountsRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Gebruikersbeheer" />
      </ListItem>
    </>
  );
};

export default NavigationbarSuperAdmin;
