import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { COMPANY_BY_ID } from "../../components/Queries/GraphQLQuerys";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";
import CompanyModal from "./CompanyPageModal";
import { CompanyDataTL } from "../../components/Models/TeamleaderModels";

const CompanyPage = () => {
  const { userData } = useAuthContext();
  const { runQuery } = useCrmContext();
  const [CompanyPageCrmData, setCompanyPageCrmData] = useState<CompanyDataTL>();
  
  const getCompanyData = async () => {
    const companyId = userData?.crmid;
    if (companyId) {
      const variables = { companyId: `${companyId}` };
      const crmdataCompanypage = await runQuery(COMPANY_BY_ID, variables);
      setCompanyPageCrmData(crmdataCompanypage.companyById);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, [userData?.crmid]);

  return (
    <>
      <h1>Overzicht bedrijfsgegevens</h1>
      {CompanyPageCrmData ? (
        <>
          <CompanyModal
            companyData={{
              name: CompanyPageCrmData?.name,
              emails: CompanyPageCrmData?.emails,
              telephones: CompanyPageCrmData?.telephones,
              website: CompanyPageCrmData?.website,
              primary_address: CompanyPageCrmData?.primary_address,
              addresses: CompanyPageCrmData?.addresses,
              marketing_mails_consent: CompanyPageCrmData?.marketing_mails_consent,
            }}
            getCompanyData={getCompanyData}
          />
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={4} md={4}>
              <h4>Bedrijfsnaam:</h4>
              <h4>{CompanyPageCrmData?.name}</h4>
            </Grid>
  
            <Grid item xs={4} sm={4} md={4}>
              <h4>Website:</h4>
              <h4>
                {CompanyPageCrmData?.website ? CompanyPageCrmData?.website : "/"}
              </h4>
            </Grid>
  
            <Grid item xs={4} sm={4} md={4}>
              <h4>Primaire E-mail:</h4>
              <h4>{CompanyPageCrmData?.emails[0].email}</h4>
            </Grid>
  
            <Grid item xs={4} sm={4} md={4}>
              <h4>Marketing mails:</h4>
              <h4>
                {CompanyPageCrmData?.marketing_mails_consent ? "Ja" : "Nee"}
              </h4>
            </Grid>
  
            <Grid item xs={4} sm={4} md={4}>
              <h4>Primaire address:</h4>
              <h4>{CompanyPageCrmData?.primary_address.line_1}</h4>
              <h4>
                {CompanyPageCrmData?.primary_address.postal_code}{" "}
                {CompanyPageCrmData?.primary_address.city}{" "}
                {CompanyPageCrmData?.primary_address.country}
              </h4>
            </Grid>
  
            <Grid item xs={4} sm={4} md={4}>
              <h4>Primair telefoonnummer:</h4>
              <h4>{CompanyPageCrmData?.telephones[0].number}</h4>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="loadinghomepagecompany">
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );  
};

export default CompanyPage;
