import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { CONTACT_BY_COMPANY_ID } from "../../components/Queries/GraphQLQuerys";
import { ContactData } from "../../components/Models/TeamleaderModels";
import ContactModal from "./EmployeeModal";

const EmployeePage = () => {
  const { runQuery } = useCrmContext();
  const { userData } = useAuthContext();
  const [employeeData, setEmployeeData] = useState<ContactData[]>([]);

  const getEmployeeData = async () => {
    const companyId = userData?.crmid;
    if (companyId) {
      const varContacts = { companyId: `${companyId}` };
      const contactsByCompany = await runQuery( CONTACT_BY_COMPANY_ID, varContacts);
      setEmployeeData(contactsByCompany?.contacts);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, [userData?.crmid]);

  return (
    <>
      <div>
        <h1>Medewerkers</h1>
        <Box
          sx={{
            height: 400,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <DataGrid
            rows={employeeData}
            columns={columns(getEmployeeData)}
            loading={!employeeData.length}
            style={{ width: "90%", height: "50vh", color: "black" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                  page: 0,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </>
  );
};

export default EmployeePage;

const columns = (getEmployeeData: () => void): GridColDef[] => [
  {
    field: "first_name",
    headerName: "Voornaam",
    width: 150,
  },
  {
    field: "last_name",
    headerName: "Achternaam",
    width: 150,
  },
  {
    field: "emails.email",
    headerName: "E-mail",
    width: 300,
    valueGetter: (params) => params.row.emails?.[0]?.email,
  },
  {
    field: "marketing_mails_consent",
    headerName: "Marketing mails?",
    width: 150,
    type: "boolean",
  },
  {
    field: "birthdate",
    headerName: "Geboorte datum",
    width: 125,
  },
  {
    field: "language",
    headerName: "Taal",
    flex: 1,
  },
  {
    field: "edit button",
    headerName: "",
    width: 75,
    renderCell: (params) => {
      return (
        <ContactModal
          contactData={params.row as ContactData}
          idToUpdateUser={params.row.id as string}
          getEmployeeData={getEmployeeData}
        />
      );
    },
  },
];
