import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";

const SendResetEmail = (email: string) => {
  const auth = getAuth();

  sendPasswordResetEmail(auth, email)
    .then(() => {
    })
    .catch((error: any) => { }
    );

  return true
}

const loginUser = async (email: string, password: string) => {
  const auth = getAuth();
  let signedIn: any = { user: undefined, result: false, idToken: undefined }
  try {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        signedIn.user = user
        signedIn.result = true
      })
      .catch((error: any) => {
        signedIn.user = false
        signedIn.result = false
      });
  } catch (error) { }

  if (signedIn?.user) {
    let token: any = await signedIn?.user.getIdToken(true)
    signedIn.idToken = token;
  }

  return signedIn;
}

const logoutUser = async () => {
  let logoutValue: any = false;
  const auth = getAuth();
  signOut(auth).then(() => {
    logoutValue = false;
  }).catch((error) => {
    logoutValue = true;
  });

  return logoutValue;
}

export { logoutUser, loginUser, SendResetEmail }