export const getRoleName = (role: string) => {
  switch (role) {
    case "superadmin":
      return "Beheerder";
    case "admin":
      return "Administratie";
    default:
      return "Gebruiker";
  }
};

export const getRoleFromName = (roleName: string) => {
  switch (roleName) {
    case "Beheerder":
      return "superadmin";
    case "Administratie":
      return "admin";
    default:
      return "user";
  }
};

export function isValidSuperadmin(userData: { role: string }) {
  const authUser = localStorage.getItem("authUser");
  const userRole = userData?.role;
  return authUser && userRole !== "user" && userRole !== "admin";
}

export function isValidAdmin(userData: { role: string }) {
  const authUser = localStorage.getItem("authUser");
  const userRole = userData?.role;
  return authUser && userRole !== "user";
}
