export const getAbrvFromLang = (lang: string) => {
    switch (lang) {
      case "English":
        return "en";
      case "Français":
        return "fr";
      default:
        return "nl";
    }
};

export const getLangFromAbrv = (lang: string) => {
    switch (lang) {
      case "en":
        return "English";
      case "fr":
        return "Français";
      default:
        return "Nederlands";
    }
};