import { Route, Redirect } from 'react-router-dom';

import { useAuthContext } from "../Context/AuthContext/FirebaseAuthenticationWithContext"

const PrivateRoute = (props: any) => {
  const { userState } = useAuthContext();

  if (!!userState) {
    return (
      <Route path={props.path}>
        {props.children}
      </Route>)
  }

  else {
    localStorage.setItem('requestedPath', window.location.pathname);
    return (<Redirect to={'/login'}></Redirect>)
  }
}
 
export default PrivateRoute;