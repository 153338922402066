const COMPANY_BY_ID: string = `query getCompanybyId ($companyId: String) {
  companyById (id: $companyId) {
    id
    name
    vat_number
    emails {
      type
      email
    }
    telephones {
      type
      number
    }
    primary_address {
      line_1
      country
      city
      postal_code
    }
    addresses {
      type
    }
    website
    marketing_mails_consent
  }
}`

const CONTACT_BY_COMPANY_ID: string = `query ($companyId: String) {
  contacts(filter: { company_id: $companyId }) {
    id
    first_name
    last_name
    birthdate
    marketing_mails_consent
    telephones {
      type
      number
    }
    emails {
      type
      email
    }
    language
  }
}`

const ALL_COMPANIES: string = `
  query {
    companies {
      id
      name
    }
  }
`;

const INVOICES_BY_COMPANY_ID_LENGTH: string = `query getinvoices($companyId: String) {
  invoices(page: { size: 1000000000 } filter: { customer: { type: "company", id: $companyId } }) {
    id
    status
  }
}`

const INVOICES_BY_COMPANY_ID: string = `query getinvoices($companyId: String, $pageNumber: Float) {
  invoices(page: { size: 10, number: $pageNumber } filter: { customer: { type: "company", id: $companyId } }) {
    id
    invoice_date
    invoicee_name
    status
    paid
    payable
  }
}`

const INVOICE_DOWNLOAD_BY_INVOICE_ID: string = `query GetInvoiceDownload($id: String!, $format: String!) {
  invoiceDownload(id: $id, format: $format) {
    location
    expires
  }
}`

const CREDITNOTES_BY_COMPANY_ID_LENGTH: string = `query getCreditNotes($companyId: String) {
  creditnotes( page: { size: 1000000000 } filter: { customer: { id: $companyId, type: "company" }}) {
    id
  }
}`

const CREDITNOTES_BY_COMPANY: string = `query getCreditNotes($companyId: String, $pageNumber: Float) {
  creditnotes(page: { size: 10, number: $pageNumber } filter: { customer: { id: $companyId, type: "company" } }) {
    id
    credit_note_number
    paid
    status
    invoicee_name
    tax_inclusive
    tax_exclusive
    invoicee_vat_number
  }
}`

const CREDITNOTE_DOWNLOAD_BY_CREDITNOTE_ID: string = `query GetInvoiceDownload($id: String!, $format: String!) {
  creditNoteDownload(id: $id, format: $format) {
    location
    expires
  }
}`

export {
  COMPANY_BY_ID,
  CONTACT_BY_COMPANY_ID,
  INVOICES_BY_COMPANY_ID_LENGTH,
  INVOICES_BY_COMPANY_ID,
  INVOICE_DOWNLOAD_BY_INVOICE_ID,
  CREDITNOTES_BY_COMPANY_ID_LENGTH,
  CREDITNOTES_BY_COMPANY,
  CREDITNOTE_DOWNLOAD_BY_CREDITNOTE_ID,
  ALL_COMPANIES
}