import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import "./ContactPage.css";

const ContactModal = ({ employee }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button
        onClick={handleOpen}
        style={{
          backgroundColor: "white",
          color: "black",
          cursor: "pointer",
          border: "1px solid black",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        Meer Info
      </button>
      <Modal open={open} onClose={handleClose}>
        <Box className="modalContact">
          <h3>{employee?.name}</h3>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={4} sm={4} md={6}>
              <h4>Rol:</h4>
              <h4>{employee?.role}</h4>
            </Grid>

            <Grid item xs={4} sm={4} md={6}>
              <h4>E-mail:</h4>
              <h4>{employee?.email}</h4>
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
              <Button
                variant="contained"
                onClick={handleClose}
                style={{ marginTop: "3rem", width: '100px', backgroundColor: '#E2222F'}}
              >
                Sluiten
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactModal;
