import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { getRoleName } from "../../components/Helpers/userRoleChecker";
import { COMPANY_BY_ID } from "../../components/Queries/GraphQLQuerys";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";

const HomeDataGrid = () => {
  const { userData } = useAuthContext();
  const { runQuery } = useCrmContext();
  const [homePageCrmData, sethomePageCrmData] = useState<any>();

  useEffect(() => {
    const getContactbyidCrmData = async () => {
      if (userData?.crmid) {
        const variables = {
          companyId: `${userData?.crmid}`
        };

        const crmdatahomepage: any = await runQuery(
          COMPANY_BY_ID,
          variables
        );
        sethomePageCrmData(crmdatahomepage?.companyById);
      }
    };

    getContactbyidCrmData();
  }, [userData]);

  return (
    <>
      {homePageCrmData ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <h4>Naam:</h4>
            <h4>{userData?.fullname}</h4>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <h4>E-mail:</h4>
            <h4>{userData?.email}</h4>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <h4>Rol:</h4>
            <h4>{getRoleName(userData?.role)}</h4>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <h4>Bedrijf:</h4>
            <h4>{homePageCrmData?.name ? homePageCrmData?.name : "Nee"}</h4>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <h4>Geboortedatum:</h4>
            {/* <h4>
              {homePageCrmData?.birthdate ? homePageCrmData?.birthdate : "/"}
            </h4> */}
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <h4>Marketing mails:</h4>
            <h4>{homePageCrmData?.marketing_mails_consent ? "Ja" : "Nee"}</h4>
          </Grid>
        </Grid>
      ) : (
        <>
          <div className="loadinghomepagecompany">
            <CircularProgress color="secondary" />
          </div>
        </>
      )}
    </>
  );
};

export default HomeDataGrid;
