import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { FirebaseUser } from "../../components/Models/FirebaseModels";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";
import { MenuItem } from "@mui/material";
import CloseModalButton from "../../components/Helpers/exitModalButton";
import { AllTLCompanies } from "../../components/Models/TeamleaderModels";

interface UserAddModalProps {
  buttonText: string;
  onDataRefresh: () => void;
  cogetix: boolean;
  TLCompanies: AllTLCompanies[]
}

export const UserAddModal = ({ buttonText, onDataRefresh, cogetix, TLCompanies }: UserAddModalProps) => {
  const { userData } = useAuthContext();
  const handleOpen = () => {
    setOpen(true);
    setFormData({
      crmid: "",
      email: "",
      fullname: "",
      passwordcreated: true,
      role: ""
    });
  };
  const handleClose = () => setOpen(false);
  const { createFirebaseUser } = useCrmContext();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FirebaseUser>({
    crmid: "",
    email: "",
    fullname: "",
    passwordcreated: true,
    role: ""
  });

  const handleRoleChange = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      role: value || ""
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleCrmIdChange = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      crmid: value || ""
    }));
  };

  const handleSave = async () => {
    const updatedFormData = !cogetix ? { ...formData, crmid: userData?.crmid || "" } : formData;
    await createFirebaseUser(updatedFormData);
    handleClose();
    onDataRefresh();
  };

  return (
    <div>
      <Button
        style={{
          color: "var(--cogetixRed)",
          border: "none",
          background: "transparent",
        }}
        onClick={handleOpen}
      >
        {buttonText}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          className="modalContact"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <CloseModalButton handleClose={handleClose} />
          <h3>{cogetix ? 'Cogetix: Eerste Superadmin aanmaken' : 'Portaal account aanmaken'}</h3>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            {cogetix && (
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Bedrijfs Id"
                    id="crmid"
                    value={formData.crmid}
                    onChange={handleCrmIdChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    {TLCompanies && TLCompanies.map(company => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Vol naam"
                id="fullname"
                value={formData.fullname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Email"
                id="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Rol"
                  id="role"
                  value={formData.role}
                  onChange={handleRoleChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="Gebruiker">Gebruiker</MenuItem>
                  <MenuItem value="Administratie">Administratie</MenuItem>
                  <MenuItem value="Beheerder">Beheerder</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{ marginTop: "1rem", backgroundColor: "#E2222F" }}
              >
                <CheckRoundedIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

interface UserEditModalProps {
  firebaseUserData: FirebaseUser;
  idToUpdateUser: string;
  onDataRefresh: () => void;
}

export const UserEditModal = ({ idToUpdateUser, onDataRefresh, firebaseUserData }: UserEditModalProps) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FirebaseUser>(firebaseUserData);
  const { updateFirebaseUser } = useCrmContext();

  const handleOpen = () => {
    setOpen(true);
    setFormData(firebaseUserData);
  };
  const handleClose = () => setOpen(false);

  const handleRoleChange = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      role: value || ""
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSave = async () => {
    await updateFirebaseUser(formData, idToUpdateUser);
    onDataRefresh();
    handleClose();
  };

  return (
    <div>
      <Button style={{color: 'var(--cogetixRed)', border: "none", background: "transparent"}} onClick={handleOpen}>
        <EditRoundedIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="modalContact" sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflowY: 'auto'
        }}>
          <CloseModalButton handleClose={handleClose} />
          <h3>Portaal account aanpassen</h3>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Vol naam"
                id="fullname"
                value={formData.fullname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Email"
                id="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Rol"
                  id="role"
                  value={formData.role}
                  onChange={handleRoleChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="Gebruiker">Gebruiker</MenuItem>
                  <MenuItem value="Administratie">Administratie</MenuItem>
                  <MenuItem value="Beheerder">Beheerder</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{ marginTop: "1rem", backgroundColor: '#E2222F'}}
              >
                <CheckRoundedIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

interface UserDeleteModalProps {
  idToDeleteUser: string;
  onDataRefresh: () => void;
}

export const UserDeleteModal = ({ idToDeleteUser, onDataRefresh }: UserDeleteModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { deleteFirebaseUser } = useCrmContext();

  const handleSave = async () => {
    await deleteFirebaseUser(idToDeleteUser);
    handleClose();
    onDataRefresh();
  };

  return (
    <div>
      <Button style={{color: 'var(--cogetixRed)', border: "none", background: "transparent"}} onClick={handleOpen}>
        <DeleteForeverRoundedIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="modalContact" sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '40vw',
          maxHeight: '20vh',
        }}>
          <CloseModalButton handleClose={handleClose} />
          <h3>Bent u zeker dat u deze account wilt verwijderen?</h3>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{ marginTop: "1rem", backgroundColor: '#E2222F'}}
              >
                <DeleteForeverRoundedIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};