import { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAuthContext } from "../Context/AuthContext/FirebaseAuthenticationWithContext";

function LogoutModal() {
  const { tryLogout } = useAuthContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorLoginMessage, setErrorLoginMessage] = useState<string>("");

  const handleLogout = async () => {
    const returnValue: any = await tryLogout();
    window.location.href = "/login";

    if (returnValue) {
      setErrorLoginMessage(returnValue);
    }
  };

  return (
    <div>
      <List>
        <ListItem button onClick={handleOpen}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Uitloggen" />
        </ListItem>
      </List>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalBoxLogout">
          <div>
            {errorLoginMessage ? (
              <div>
                <p className="ErrorMessage">{errorLoginMessage}</p>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="LogoutText"
          >
            Weet je zeker dat je wil uitloggen?
          </Typography>
          <Button
            variant="contained"
            style={{
              margin: "1em",
              paddingLeft: "2em",
              paddingRight: "2em",
              backgroundColor: "green",
            }}
            onClick={handleLogout}
          >
            Ja
          </Button>
          <Button
            variant="contained"
            style={{
              margin: "1em",
              paddingLeft: "2em",
              paddingRight: "2em",
              backgroundColor: "var(--cogetixRed)",
            }}
            onClick={handleClose}
          >
            Nee
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default LogoutModal;
