import { getDatabase, ref, get, child } from "firebase/database";

export const getUserFromDb = async (userid: any) => {
  let allData: any = ""
  const dbRef: any = await ref(getDatabase());

  await get(child(dbRef, `users/${userid}`)).then((snapshot: any) => {
    if (snapshot.exists()) {
      allData = snapshot.val()
    } else { }
  }).catch((error: any) => { });

  if (allData) {
    return allData
  }

  return false
}