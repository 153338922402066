import {
    CREDITNOTES_BY_COMPANY_ID_LENGTH,
    CREDITNOTES_BY_COMPANY,
    CREDITNOTE_DOWNLOAD_BY_CREDITNOTE_ID
} from "../../components/Queries/GraphQLQuerys";

const getCreditNotes = async (companyId: string, pageNumber: number = 0, runQuery: any, setCreditNotesData: any, setStartLoading: any) => {
    setStartLoading(true);
    if (companyId) {
        // Get creditnotes by companyId
        const variables = {
            companyId: `${companyId}`,
            pageNumber: pageNumber + 1,
        };
        const { creditnotes } = await runQuery(
            CREDITNOTES_BY_COMPANY,
            variables
        );

        setCreditNotesData(creditnotes);
    }
    setStartLoading(false);
};

const getCreditNotesLength = async (companyId: string, runQuery: any, setCreditNotesLength: any) => {
    if (companyId) {
        // Get creditNotesLength from companyId
        const variables = {
            companyId: `${companyId}`,
        };
        const { creditnotes }: any = await runQuery(
            CREDITNOTES_BY_COMPANY_ID_LENGTH,
            variables
        );
        setCreditNotesLength(creditnotes?.length);
    }
};

const getCreditNoteDownloadLink = async (creditNoteId: string, runQuery: any) => {
    const variables = {
        id: `${creditNoteId}`,
        format: 'pdf',
    };
    const response = await runQuery(
        CREDITNOTE_DOWNLOAD_BY_CREDITNOTE_ID,
        variables
    );
    const location = response.creditNoteDownload.location;
    return location;
  };

  export { getCreditNotes, getCreditNotesLength, getCreditNoteDownloadLink }
