import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { getCreditNotes, getCreditNotesLength, getCreditNoteDownloadLink } from "./handleCreditNotes";
import { isValidAdmin } from "../../components/Helpers/userRoleChecker";
import { Redirect } from "react-router-dom";

const CreditNotesPage = () => {
  const { runQuery } = useCrmContext();
  const { userData } = useAuthContext();

  const [startLoading, setStartLoading] = useState(false);
  const [creditNotesData, setCreditNotesData] = useState([]);
  const [creditNotesLength, setCreditNotesLength] = useState(10);

  useEffect(() => {
    getCreditNotesLength(userData?.crmid, runQuery, setCreditNotesLength);
    getCreditNotes(
      userData?.crmid,
      0,
      runQuery,
      setCreditNotesData,
      setStartLoading
    );
  }, [userData]);

  const handlePaginationModel = async (props: any) => {
    await getCreditNotes(
      userData?.crmid,
      props?.page,
      runQuery,
      setCreditNotesData,
      setStartLoading
    );
  };

  //tussen function om runquery door te geven
  const handleDownloadLink = async (
    id: string
  ): Promise<string | URL | undefined> => {
    const downloadLink: string = await getCreditNoteDownloadLink(id, runQuery);
    return downloadLink;
  };

  return isValidAdmin(userData?.role) ? (
    <>
      <div>
        <h1>Creditnota's</h1>
        <Box
          sx={{
            height: 400,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <DataGrid
            rows={creditNotesData?.length > 0 ? creditNotesData : []}
            rowCount={creditNotesLength}
            columns={columns(handleDownloadLink)}
            loading={startLoading}
            paginationMode="server"
            style={{ width: "90%", height: "50vh", color: "black" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                  page: 0,
                },
              },
            }}
            onPaginationModelChange={handlePaginationModel}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </>
  ) : (
    <>
      <Redirect to="/" />
    </>
  );
};

export default CreditNotesPage;

const columns = (
  handleDownloadLink: (id: string) => Promise<string | URL | undefined>
): GridColDef[] => [
  {
    field: "invoicee_name",
    headerName: "Ontvanger",
    width: 200,
  },
  {
    field: "invoicee_vat_number",
    headerName: "BTW Nummer",
    width: 200,
  },
  {
    field: "tax_inclusive",
    headerName: "Te betalen bedrag (Incl BTW)",
    width: 200,
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params?.row?.tax_inclusive
          ? "€ " + params?.row?.tax_inclusive
          : "Geen bedrag bekend"
      }`,
  },
  {
    field: "paid",
    headerName: "Betaald?",
    width: 200,
    type: "boolean",
    valueGetter: (params: GridValueGetterParams) => params.row.paid === "true", // Kleine aanpassing om check-marks niet altijd op true te laten staan
  },
  {
    field: "download",
    headerName: "",
    width: 150,
    renderCell: (params: GridCellParams) => {
      return (
        <Button
          onClick={async () => {
            const downloadLink: string | URL | undefined =
              await handleDownloadLink(params.row.id);
            if (downloadLink) {
              const a = document.createElement("a");
              a.href = downloadLink.toString();
              a.download = "filename";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }}
          startIcon={<FileDownloadRoundedIcon />}
          style={{ color: "var(--cogetixRed)" }}
        >
          Download
        </Button>
      );
    },
  },
];
