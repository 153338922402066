import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import { useAuthContext } from "../../components/Context/AuthContext/FirebaseAuthenticationWithContext";
import { isValidSuperadmin } from "../../components/Helpers/userRoleChecker";
import { FirebaseUser } from "../../components/Models/FirebaseModels";
import { getRoleName } from "../../components/Helpers/userRoleChecker";
import { UserAddModal, UserDeleteModal, UserEditModal } from "./UserManagementModal";
import { ALL_COMPANIES } from "../../components/Queries/GraphQLQuerys";
import { AllTLCompanies } from "../../components/Models/TeamleaderModels";

const UserManagementPage = () => {
  const { userData } = useAuthContext();
  const { getAllFirebaseUsers, runQuery } = useCrmContext();

  const [FirebaseUsersData, setFirebaseUsersData] = useState<FirebaseUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCogetixEmail, setIsCogetixEmail] = useState(false);
  const [allTLCompanies, setAllTLCompanies] = useState<AllTLCompanies[]>([]);

  const getFirebaseData = async () => {
    if(userData) {
      const cogetixEmailCheck: string = userData.email;
      setIsCogetixEmail(cogetixEmailCheck.includes("@cogetix.com"));
      setLoading(true);
      try {
        const usersObject: Record<string, FirebaseUser> = await getAllFirebaseUsers();
        const usersArray = Object.values(usersObject);
        setFirebaseUsersData(
          usersArray?.map((user) => ({
            ...user,
            role: getRoleName(user?.role),
          })) || []
        );
      } catch (error) {
        console.error("Error fetching Firebase users:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getFirebaseData();
  }, [userData, getAllFirebaseUsers]);

  useEffect(() => {
    const fetchTLCompanies = async () => {
      if (isCogetixEmail) {
        try {
          const TLCompanyList: any = await runQuery(ALL_COMPANIES);
          const companies = TLCompanyList.companies || [];
          setAllTLCompanies(companies.map(
            (item: { id: string; name: string }) => ({
              id: item.id,
              name: item.name,
            })
          ));
        } catch (error) {
          console.error("Error fetching TL companies:", error);
        }
      }
    };

    fetchTLCompanies();
  }, [isCogetixEmail, runQuery]);

  const handleDataRefresh = async () => {
    await getFirebaseData();
  };

  return isValidSuperadmin(userData?.role) ? (
    <>
      <div>
        <h1>Portaalaccounts</h1>
        <Box
          sx={{
            height: 400,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <UserAddModal buttonText="Voeg Portaal Gebruiker toe" onDataRefresh={handleDataRefresh} cogetix={isCogetixEmail} TLCompanies={allTLCompanies}/>
          <DataGrid
            rows={FirebaseUsersData}
            getRowId={(row) => row.uid || "defaultId"}
            columns={columns(handleDataRefresh)}
            loading={loading}
            style={{ width: "90%", height: "50vh", color: "black" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                  page: 0,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </>
  ) : (
    <>
      <Redirect to="/" />
    </>
  );
};

export default UserManagementPage;

const columns = (handleDataRefresh: () => void): GridColDef[] => [
  {
    field: "fullname",
    headerName: "Naam",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
  },
  {
    field: "role",
    headerName: "Rol",
    flex: 1,
  },
  {
    field: "edit button",
    headerName: "",
    align: "center",
    width: 50,
    renderCell: (params: { row: FirebaseUser; }) => {
      return (
        <UserEditModal
        firebaseUserData={params.row as FirebaseUser}
        idToUpdateUser={params.row.uid as string}
        onDataRefresh={handleDataRefresh}
        />
      );
    },
  },
  {
    field: "delete button",
    headerName: "",
    align: "center",
    width: 50,
    renderCell: (params: { row: FirebaseUser; }) => {
      return (
        <UserDeleteModal
        idToDeleteUser={params.row.uid as string}
        onDataRefresh={handleDataRefresh}
        />
      );
    },
  },
];
