import { ResetPasswordData } from '../../components/Models/UserModelContext'

export const GetActionHandlerUrl = () => {
  let returnValueReset: ResetPasswordData;
  const mode = getParameterByName('mode');
  const actionCode = getParameterByName('oobCode');

  if (mode == "resetPassword" && actionCode) {
    returnValueReset = { mode: mode, actionCode: actionCode, succeeded: true }
    return returnValueReset;
  }

  else {
    returnValueReset = { mode: undefined, actionCode: undefined, succeeded: false }
    return returnValueReset
  }
}

const getParameterByName = (name: string, url: any = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
