import React from "react";
import Button from "@mui/material/Button";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

interface CloseModalButtonProps {
  handleClose: () => void;
}

const CloseModalButton: React.FC<CloseModalButtonProps> = ({ handleClose }) => {
  return (
    <Button
      onClick={handleClose}
      style={{ position: "absolute", top: "10px", right: "10px",  color: "var(--cogetixRed)" }}
    >
      <ClearRoundedIcon />
    </Button>
  );
};

export default CloseModalButton;