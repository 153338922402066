import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { ResetVerifyPassword } from '../../components/Models/UserModelContext'

export const handleResetPassword = async (auth: any, actionCode: any) => {
  let verifyresetcode: ResetVerifyPassword = { actionCode: "", email: "", message: "", succeeded: false };

  await verifyPasswordResetCode(auth, actionCode).then((email: string) => {
    verifyresetcode = { actionCode: actionCode, email: email, message: "Valid code", succeeded: true }
  }).catch((error) => {
    verifyresetcode = { actionCode: undefined, email: undefined, message: "Ongeldige of verlopen link. Contacteer Cogetix voor hulp.", succeeded: false }
  });

  return verifyresetcode;
}

export const passwordResetConfirm = async (actionCode: string, auth: any, newPassword: string) => {
  let resetMessage: boolean = false;

  let message: any = await confirmPasswordReset(auth, actionCode, newPassword).then((resp: any) => {
    resetMessage = true
    return true
  }).catch((error) => {
    resetMessage = false;
    return false
  });

  return resetMessage;
}