import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { ReactTagManager } from 'react-gtm-ts';
import SnackbarProviderWrapper from './components/Helpers/snackbarWrapper';

import PrivateRoute from './components/Auth/PrivateRoute';
import { firebaseConfig } from './components/Auth/FirebaseConfig';
import FirebaseAuthenticationWithContext from './components/Context/AuthContext/FirebaseAuthenticationWithContext';
import Navigation from './components/Navigation/Navigation';
import CrmRequestsContext from './components/Context/CrmContext/CrmRequestsContext';
import './App.css';

import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import EmployeePage from './pages/EmployeePage/EmployeePage';
import InvoicePage from './pages/InvoicePage/InvoicePage';
import CreditNotesPage from './pages/CreditNotesPage/CreditNotesPage';
import ContactPage from './pages/ContactPage/ContactPage';
import UserManagementPage from './pages/UserManagementPage/UserManagementPage';
import CompanyPage from './pages/CompanyPage/CompanyPage';

function App() {
  initializeApp(firebaseConfig);
  ReactTagManager.init({
    code: process.env.REACT_APP_TAGMANAGER_ID ?? '', 
    debug: false,
    performance: false,
  });

  return (
    <div className="App">
      <SnackbarProviderWrapper>
      <FirebaseAuthenticationWithContext>
        <CrmRequestsContext>
          <Router>
            <Navigation>
              <Switch>
                <Route path="/changepassword">
                  <PasswordResetPage />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <PrivateRoute path="/contact">
                  <ContactPage />
                </PrivateRoute>
                <PrivateRoute path="/bedrijf">
                  <CompanyPage />
                </PrivateRoute>
                <PrivateRoute path="/creditnotes">
                  <CreditNotesPage />
                </PrivateRoute>
                <PrivateRoute path="/facturen">
                  <InvoicePage />
                </PrivateRoute>
                <PrivateRoute path="/medewerkers">
                  <EmployeePage />
                </PrivateRoute>
                <PrivateRoute path="/gebruikersbeheer">
                  <UserManagementPage />
                </PrivateRoute>
                <PrivateRoute path="/">
                  <HomePage />
                </PrivateRoute>
              </Switch>
            </Navigation>
          </Router>
        </CrmRequestsContext>
      </FirebaseAuthenticationWithContext>
      </SnackbarProviderWrapper>
    </div>
  );
}

export default App;