import { useContext } from "react";
import { getAuth, getIdToken } from "@firebase/auth";

import { useAuthContext } from "../AuthContext/FirebaseAuthenticationWithContext";
import { CrmContext } from "./CrmContext";
import { ContactData, CompanyData } from "../../Models/TeamleaderModels";
import { FirebaseUser } from "../../Models/FirebaseModels";
import { getRoleFromName } from "../../Helpers/userRoleChecker";
import { getAbrvFromLang } from "../../Helpers/userLangChecker";
import { useSnackbar } from "notistack";

export const useCrmContext = () => useContext(CrmContext);

const CrmRequestsContext = (props: any) => {
  const { userData } = useAuthContext();
  const auth: any = getAuth();
  const { enqueueSnackbar } = useSnackbar();

  const runQuery = async (query: string, variables: any) => {
    if (userData?.crmid && auth?.currentUser) {
      const idtokenLoggedInUser: any = await getIdToken( auth?.currentUser, true );

      const CrmDataBody: any = {
        query: query,
        crmId: userData.crmid,
        variables: JSON.stringify(variables),
      };
      const crmdata: any = await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${idtokenLoggedInUser}`,
          },
          body: JSON.stringify(CrmDataBody),
        }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {});
      const crmDatajson = await crmdata?.json();
      return crmDatajson;
    }
  };

  const updateTeamleaderContact = async ( updateValuesBody: ContactData, idToUpdateUser: string
  ) => {
    try {
      const authorizedUserToken: any = await getIdToken( auth?.currentUser, true );
      const language = getAbrvFromLang(updateValuesBody.language);
      const updatedValues = {
        ...updateValuesBody,
        language: language,
      };
      const DataBody: any = {
        updateValues: updatedValues,
        idToUpdateUser: idToUpdateUser,
      };
      await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/updateTeamleaderContact",
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${authorizedUserToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(DataBody),
        }
      ).then((response) => {
        if (response.ok) {
          enqueueSnackbar("Medewerker aangepast", { variant: "success" });
        } else {
          enqueueSnackbar("Fout bij aanpassen medewerker", { variant: "error" });
        }
        return response;
      });
    } catch (error) {
      enqueueSnackbar("Fout bij aanpassen medewerker", { variant: "error" });
    }
  };

  const updateTeamleaderCompany = async (updateValuesBody: CompanyData) => {
    try {
      const authorizedUserToken: any = await getIdToken( auth?.currentUser, true );
      const DataBody: any = {
        updateValues: updateValuesBody,
      };
      await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/updateTeamleaderCompany",
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${authorizedUserToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(DataBody),
        }
      ).then((response) => {
        if (response.ok) {
          enqueueSnackbar("Bedrijfsgegevens aangepast", { variant: "success" });
        } else {
          enqueueSnackbar("Fout bij aanpassen bedrijfsgegevens", { variant: "error" });
        }
        return response;
      });
    } catch (error) {
      enqueueSnackbar("Fout bij aanpassen bedrijfsgegevens", { variant: "error" });
    }
  };

  const getAllFirebaseUsers = async () => {
    try {
      const authorizedUserToken = await getIdToken(auth?.currentUser, true);
      const response = await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/readFirebaseUsers",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authorizedUserToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const getAllUsersResponseJson = await response.json();
      return getAllUsersResponseJson;
    } catch (error) {
      return null;
    }
  };

  const createFirebaseUser = async (userValues: FirebaseUser) => {
    try {
      const authorizedUserToken = await getIdToken(auth?.currentUser, true);
      const role = getRoleFromName(userValues.role);
      const updatedValues = {
        ...userValues,
        role: role,
      };
      const DataBody: any = {
        userValues: updatedValues,
      };
      await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/createFirebaseUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authorizedUserToken}`,
          },
          body: JSON.stringify(DataBody),
        }
      ).then((response) => {
        if (response.ok) {
          enqueueSnackbar("Portaalaccount aanmaken", { variant: "success" });
          return response.json();
        } else {
          enqueueSnackbar("Fout bij aanmaken portaalaccount", { variant: "error" });
          return null;
        }
      });
    } catch (error) {
      enqueueSnackbar("Fout bij aanmaken portaalaccount", { variant: "error" });
      return null;
    }
  };

  const updateFirebaseUser = async ( updateValues: FirebaseUser,idToUpdateUser: string ) => {
    try {
      const authorizedUserToken = await getIdToken(auth?.currentUser, true);
      const role = getRoleFromName(updateValues.role);
      const updatedValues = {
        ...updateValues,
        role: role,
      };
      const DataBody: any = {
        updateValues: updatedValues,
        idToUpdate: idToUpdateUser,
      };
      await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/updateFirebaseUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authorizedUserToken}`,
          },
          body: JSON.stringify(DataBody),
        }
      ).then((response) => {
        if (response.ok) {
          enqueueSnackbar("Portaalaccount aangepast", { variant: "success" });
          return response.json();
        } else {
          enqueueSnackbar("Fout bij aanpassen portaalaccount", { variant: "error" });
          return null;
        }
      });
    } catch (error) {
      enqueueSnackbar("Fout bij aanpassen portaalaccount", { variant: "error" });
      return null;
    }
  };

  const deleteFirebaseUser = async (idToDeleteUser: string) => {
    try {
      const authorizedUserToken = await getIdToken(auth?.currentUser, true);
      const DataBody: any = {
        idToDelete: idToDeleteUser,
      };
      await fetch(
        "https://mycogetix-auth-handler-6y6x1622.ew.gateway.dev/deleteFirebaseUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authorizedUserToken}`,
          },
          body: JSON.stringify(DataBody),
        }
      ).then((response) => {
        if (response.ok) {
          enqueueSnackbar("Portaalaccount verwijdered", { variant: "success" });
          return response.json();
        } else {
          enqueueSnackbar("Fout bij verwijderen portaalaccount", { variant: "error" });
          return null;
        }
      });
    } catch (error) {
      enqueueSnackbar("Fout bij verwijderen portaalaccount", {variant: "error" });
      return null;
    }
  };

  return (
    <CrmContext.Provider
      value={{ runQuery, updateTeamleaderContact, createFirebaseUser, getAllFirebaseUsers, updateFirebaseUser, deleteFirebaseUser, updateTeamleaderCompany }}>
      <div>{props.children}</div>
    </CrmContext.Provider>
  );
};

export default CrmRequestsContext;
