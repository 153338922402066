import { useState, useEffect, useContext } from 'react';
import { getAuth } from "firebase/auth";

import { AuthContext } from './AuthenticationContext';
import { loginUser, logoutUser } from '../../FbRequests/UserRequests'
import { getUserFromDb } from "../../FbRequests/DbRequests"
import { ReactTagManager } from 'react-gtm-ts';

export const useAuthContext = () => useContext(AuthContext);

const FirebaseAuthenticationWithContext = (props: any) => {
  const [userState, setUserState] = useState<any>()
  const [userLoginToken, setUserLoginToken] = useState<string>()
  const [userData, setUserData] = useState<any>()
  const auth: any = getAuth();

  const authStateChanged = async () => {
    let datafromdb: any = undefined

    await auth.onAuthStateChanged(async (user: any) => {
      user ? localStorage.setItem('authUser', user) : localStorage.removeItem('authUser')
      setUserState(user);
      if (user && user?.uid) {
        datafromdb = await getUserFromDb(user.uid)
        setUserData(datafromdb)
      }
    }
    );
  }

  const trackLogout = (fullname: string) => {
    ReactTagManager.action({
      event: 'logoutUser',
      username: fullname,
    });
  };

  const trackLogin = (fullname: string) => {
    ReactTagManager.action({
      event: 'loginUser',
      username: fullname,
    });
  };

  useEffect(() => {
    authStateChanged();
  }, [])

  const tryLogout = async () => {
    let loggedOut: any = await logoutUser();
    if (loggedOut) {
      return "Fout bij uitloggen."
    }

    else {
      trackLogout(userData.fullname);
      setUserState(null)
      setUserData(undefined)
      setUserLoginToken(undefined)
      localStorage.removeItem('authUser')
      return false;
    }
  }

  const trySignIn = async (username: string, password: string) => {
    try {
      let returnloggedin = await loginUser(username, password)

      if (returnloggedin?.user) {
        localStorage.setItem('authUser', returnloggedin?.user);
        setUserState(returnloggedin?.user);
        setUserLoginToken(returnloggedin?.idToken)

        let datafromdb = await getUserFromDb(returnloggedin?.user?.uid)
        trackLogin(datafromdb.fullname);
        setUserData(datafromdb)
        return false
      }

      else {
        return "Foute gegevens";
      }

    } catch (error) { }
  }

  return (
    <AuthContext.Provider value={{ userState, trySignIn, tryLogout, userLoginToken, userData }}>
      <div>{props.children}</div>
    </AuthContext.Provider>
  )
}

export default FirebaseAuthenticationWithContext