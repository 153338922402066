import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ContactModal from "./ContactModal";

const employees = [
  {
    key: "1",
    name: "Kristof Boden",
    role: "Bestuurder",
    expertise: [],
    email: "kristof.boden@cogetix.com",
    description: "",
    imageUrl: "/images-team/KristofBoden.jpg",
  },
  {
    key: "2",
    name: "Ben Springael",
    role: "Cloud Architect",
    expertise: [],
    email: "bspringael@cogetix.com",
    description: "",
    imageUrl: "/images-team/BenSpringael.jpg",
  },
  {
    key: "3",
    name: "Sabah Assissi",
    role: "Sales Manager",
    expertise: [],
    email: "sabah.assissi@cogetix.com",
    description: "",
    imageUrl: "/images-team/sabah-assissi.jpg",
  },
  {
    key: "4",
    name: "Pieter Brat",
    role: "Mede-bedrijfsleider",
    expertise: [],
    email: "pieter.brat@cogetix.com",
    description: "",
    imageUrl: "/images-team/pieterbrat.jpg",
  },
  // Tweede 4
  {
    key: "5",
    name: "Jeroen Van Opstal",
    role: "Digital Strategy Consultant",
    expertise: [],
    email: "jeroen.vanopstal@cogetix.com",
    description: "",
    imageUrl: "/images-team/JeroenVanOpstal.jpg",
  },
  {
    key: "6",
    name: "Koen Verbrugge",
    role: "Productiviteit & Innovatie Adviseur",
    expertise: [],
    email: "koen.verbrugge@cogetix.com",
    description: "",
    imageUrl: "/images-team/KoenVerbrugge.jpg",
  },
  {
    key: "7",
    name: "Tom Nahooy",
    role: "Software Architect",
    expertise: [],
    email: "tom.nahooy@cogetix.com",
    description: "",
    imageUrl: "/images-team/TomNahooy.jpg",
  },
  {
    key: "8",
    name: "Leen Eggermont",
    role: "Finance & HR",
    expertise: [],
    email: "leen.eggermont@cogetix.com",
    description: "",
    imageUrl: "/images-team/LeenEggermont.jpg",
  },
  // Derde 4
  {
    key: "9",
    name: "Joris Gysen",
    role: "Cloud Engineer",
    expertise: [],
    email: "jgysen@cogetix.com",
    description: "",
    imageUrl: "/images-team/JorisGysen.jpg",
  },
  {
    key: "10",
    name: "Timothy Persoon",
    role: "Project Coördinator",
    expertise: [],
    email: "timothy.persoon@cogetix.com",
    description: "",
    imageUrl: "/images-team/TimothyPersoon.jpg",
  },
  {
    key: "11",
    name: "Brent Van Tilborg",
    role: "Software Engineer",
    expertise: [],
    email: "brent.vantilborg@cogetix.com",
    description: "",
    imageUrl: "/images-team/BrentVanTilborg.jpg",
  },
  {
    key: "12",
    name: "Brent Bastianen",
    role: "Developer",
    expertise: [],
    email: "brent.bastianen@cogetix.com",
    description: "",
    imageUrl: "/images-team/BrentBastianen.jpg",
  },
  // Vierde 4
  {
    key: "13",
    name: "Mike Meyers",
    role: "Developer",
    expertise: [],
    email: "mike.meyers@cogetix.com",
    description: "",
    imageUrl: "/images-team/MikeMeyers.jpg",
  },
  {
    key: "14",
    name: "Axelle Storme",
    role: "Data Administrator",
    expertise: [],
    email: "axelle.storme@cogetix.com",
    description: "",
    imageUrl: "/images-team/AxelleStorme.jpg",
  },
  {
    key: "15",
    name: "Kenny De Mulder",
    role: "Trainer",
    expertise: [],
    email: "kenny.demulder@cogetix.com",
    description: "",
    imageUrl: "/images-team/KennyDeMulder.jpg",
  }
];

const ContactPage = () => {
  return (
    <div>
      <h1>Contactgegevens</h1>
      <div style={{ marginBottom: "15vh", marginTop: "10vh" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            width={{ xs: "90vw", sm: "80vw", md: "75vw" }}
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={4} sm={8} md={12}>
              <h3 style={{ fontWeight: "800" }}>Algemene contactgegevens:</h3>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <h4>Telefoonnummer:</h4>
              <h4>+32 3 207 00 66</h4>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <h4>E-mail support:</h4>
              <h4>support@cogetix.com</h4>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <h4>Website:</h4>
              <h4>
                <a
                  href="https://www.cogetix.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  cogetix.com
                </a>
              </h4>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ marginBottom: "10vh", paddingRight: "50px", paddingLeft: "50px" }}
      >
        {employees?.map((employee) => {
          return (
            <Grid
              key={employee?.key}
              item
              xs={4}
              sm={4}
              md={3}
              style={{ marginBottom: "4rem" }}
            >
              <img
                style={{ width: 120, height: 120 }}
                src={employee?.imageUrl}
                alt={"Foto " + employee?.name}
              />
              <h4>{employee?.name}</h4>
              <ContactModal employee={employee}/>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ContactPage;
