import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";

import {
  CompanyData,
  Addresses,
} from "../../components/Models/TeamleaderModels";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import CloseModalButton from "../../components/Helpers/exitModalButton";

interface CompanyModalProps {
  companyData: CompanyData;
  getCompanyData: () => void;
}

const CompanyModal = ({ companyData, getCompanyData }: CompanyModalProps) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<CompanyData>(companyData);
  const { updateTeamleaderCompany } = useCrmContext();

  const handleOpen = () => {
    setOpen(true);
    setFormData(companyData);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    if (id === "country" || id === "city" || id === "postal_code" || id === "line_1") {
        setFormData((prevState) => ({
          ...prevState,
          primary_address: {
            ...prevState.primary_address,
            [id]: value,
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [id]: type === "checkbox" ? (checked as boolean) : value,
        }));
      }
  };

  const handleSave = async () => {
    const { line_1, country, city, postal_code } = formData.primary_address;
    const addressObject: Addresses = {
      type: "primary",
      address: {
        line_1,
        country,
        city,
        postal_code,
      },
    };

    const updatedFormData = {
      ...formData,
      addresses: [addressObject],
    };

    await updateTeamleaderCompany(updatedFormData);
    getCompanyData();
    handleClose();
  };

  const handleAddEmail = () => {
    setFormData((prevState) => ({
      ...prevState,
      emails: [...prevState.emails, { email: "", type: "" }],
    }));
  };

  const handleAddTelephone = () => {
    setFormData((prevState) => ({
      ...prevState,
      telephones: [...prevState.telephones, { number: "", type: "" }],
    }));
  };

  const handleChangeEmail = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      emails: prevState.emails.map((email, i) =>
        i === index ? { ...email, email: value } : email
      ),
    }));
  };

  const handleChangeTelephone = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setFormData((prevState) => ({
        ...prevState,
        telephones: prevState.telephones.map((telephone, i) =>
          i === index ? { ...telephone, number: value } : telephone
        ),
      }));
  };

  const handleChangeEmailType = (
    index: number,
    e: SelectChangeEvent<string>
  ) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      emails: prevState.emails.map((email, i) =>
        i === index ? { ...email, type: value as string } : email
      ),
    }));
  };

  const handleChangeTelephoneType = (
    index: number,
    e: SelectChangeEvent<string>
  ) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      telephones: prevState.telephones.map((telephone, i) =>
        i === index ? { ...telephone, type: value as string } : telephone
      ),
    }));
  };

  const handleDeleteEmail = (index: number) => {
    if (index !== 0) {
      setFormData((prevState) => ({
        ...prevState,
        emails: prevState.emails.filter((_, i) => i !== index),
      }));
    }
  };

  const handleDeleteTelephone = (index: number) => {
    if (index !== 0) {
      setFormData((prevState) => ({
        ...prevState,
        telephones: prevState.telephones.filter((_, i) => i !== index),
      }));
    }
  };

  return (
    <div>
      <Button
        style={{
          color: "var(--cogetixRed)",
          border: "none",
          background: "transparent",
        }}
        onClick={handleOpen}
      >
        Bewerken <EditRoundedIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          className="modalCompany"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            maxHeight: "80vh",
            overflowY: "auto",
            backgroundColor: "#fff",
            borderRadius: "10px",
            textAlign: "center",
            padding: "1rem",
          }}
        >
          <CloseModalButton handleClose={handleClose} />
          <h2>Bedrijfs data aanpassen</h2>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Bedrijfsnaam"
                id="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Website URL"
                id="website"
                value={formData.website}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {formData.emails.map((email, index) => (
                <div key={index}>
                  <TextField
                    label={`Email ${index + 1}`}
                    value={email.email}
                    onChange={(e) => handleChangeEmail(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />

                  <Select
                    label={`Type ${index + 1}`}
                    value={email.type}
                    onChange={(e) => handleChangeEmailType(index, e)}
                    fullWidth
                    margin="none"
                    required
                  >
                    <MenuItem value="primary">Primair</MenuItem>
                    <MenuItem value="invoicing">Invoicing</MenuItem>
                  </Select>
                  {index !== 0 && (
                    <Button
                      onClick={() => handleDeleteEmail(index)}
                      style={{ color: "var(--cogetixRed)" }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
              ))}
              <Button
                onClick={handleAddEmail}
                style={{ color: "var(--cogetixRed)" }}
              >
                <AddCircleIcon /> E-mail Toevoegen
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.marketing_mails_consent}
                    id="marketing_mails_consent"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label="Toestemming voor marketing e-mails."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Primair address (straat + huisnummer/bus)"
                id="line_1"
                value={formData.primary_address.line_1}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Postcode"
                id="postal_code"
                value={formData.primary_address.postal_code}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Stad"
                id="city"
                value={formData.primary_address.city}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Land"
                id="country"
                value={formData.primary_address.country}
                onChange={handleChange}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 2 }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {formData.telephones.map((telephone, index) => (
                <div key={index}>
                  <TextField
                    label={`Telefoon ${index + 1}`}
                    value={telephone.number}
                    onChange={(e) => handleChangeTelephone(index, e)}
                    fullWidth
                    margin="normal"
                    type="tel"
                    required
                  />

                  <Select
                    label={`Type ${index + 1}`}
                    value={telephone.type}
                    onChange={(e) => handleChangeTelephoneType(index, e)}
                    fullWidth
                    margin="none"
                    required
                  >
                    <MenuItem value="phone">Telefoon</MenuItem>
                    <MenuItem value="fax">Fax</MenuItem>
                  </Select>

                  {index !== 0 && (
                    <Button
                      onClick={() => handleDeleteTelephone(index)}
                      style={{ color: "var(--cogetixRed)" }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
              ))}
              <Button
                onClick={handleAddTelephone}
                style={{ color: "var(--cogetixRed)" }}
              >
                <AddCircleIcon /> Telefoon Toevoegen
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                onClick={handleSave}
                style={{ marginTop: "1rem", backgroundColor: "#E2222F" }}
              >
                <CheckRoundedIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CompanyModal;
