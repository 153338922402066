import "./HomePage.css";
import HomeDataGrid from "./HomeDataGrid";

const HomePage = () => {
  
  return (
    <>
      <h1>Overzicht gegevens</h1>
      <div>
        <HomeDataGrid />
      </div>
    </>
  );
};

export default HomePage;
