import { useState } from "react";
import { useHistory } from "react-router-dom";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import ContactMailIcon from "@mui/icons-material/ContactMail";

import { useAuthContext } from "../Context/AuthContext/FirebaseAuthenticationWithContext";
import LogoutModal from "./LogoutModal";
import NavigationbarAdmin from "./NavigationbarAdmin";
import "./Navigationbar.css";

const Navigationbar = (props: any) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { userData } = useAuthContext();
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeRoute = (route: any) => {
    handleDrawerClose();
    history.push(route);
  };

  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
  });

  return (
    <div>
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "white", maxWidth: "100%" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="subtitle1" noWrap component="div">
            <p style={{ color: 'black'}}>Welkom, {userData?.fullname}</p>
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        screenWidth={screenWidth}
        style={{ width: "100px" }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem className="logoCogetix" style={{ textAlign: 'left'}}>
            <ListItemIcon>
              <img
                src={process.env.PUBLIC_URL + "./CogetixLogo.png"}
                style={{ width: '50%', height: '30px'  }}
                alt="Logo"
              />
            </ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <ListItem button onClick={() => changeRoute("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => changeRoute("/medewerkers")}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Medewerkers" />
        </ListItem>
        {(userData?.role === "admin" || (userData?.role === "superadmin")) && (
          <NavigationbarAdmin
            handleDrawerClose={handleDrawerClose}
            userRole={userData?.role}
          />
        )}
        <Divider />
        <div className="logOutEnd">
          <Divider />
          <ListItem button onClick={() => changeRoute("/contact")}>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary="Contactgegevens" />
          </ListItem>

          <Divider />
          <LogoutModal />
        </div>
      </Drawer>

      <DivChildren>{props.children}</DivChildren>
    </div>
  );
};

export default Navigationbar;

const DivChildren = styled("div")(({ theme }) => ({
  maxWidth: `100vw`,
  minWidth: `calc(100vw - 85px)`,
  minHeight: "80vh",
  paddingTop: "70px",
  float: "right",
}));

// CSS
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme, screenWidth: number): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: screenWidth > 800 ? `calc(${theme.spacing(7)} + 1px)` : "0px",
  [theme.breakpoints.up("sm")]: {
    width: screenWidth > 800 ? "60px" : "0px",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface DrawerProps extends MuiDrawerProps {
  open?: boolean;
  screenWidth: number;
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })<DrawerProps>(({ theme, open, screenWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, screenWidth),
    "& .MuiDrawer-paper": closedMixin(theme, screenWidth),
  }),
}));
