import {
    INVOICES_BY_COMPANY_ID_LENGTH,
    INVOICES_BY_COMPANY_ID,
    INVOICE_DOWNLOAD_BY_INVOICE_ID,
} from "../../components/Queries/GraphQLQuerys";

import { Invoice } from "../../components/Models/InvoiceModel"

const getInvoices = async (companyId: string, pageNumber: number = 0, runQuery: any, setInvoiceData: any, setStartLoading: any) => {
    setStartLoading(true);
    if (companyId) {
        // Get invoices by companyId
        const variables = {
            companyId: `${companyId}`,
            pageNumber: pageNumber + 1,
        };
        const { invoices }: any = await runQuery(
            INVOICES_BY_COMPANY_ID,
            variables
        );
        const filteredInvoices = invoices.filter((invoice: Invoice) => invoice.status !== 'draft');
        setInvoiceData(filteredInvoices);
    }

    setStartLoading(false);
};

const getInvoicesLength = async (companyId: string, runQuery: any, setInvoiceLength: any) => {
    if (companyId) {
        // Get length of invoices by companyId
        const variables = {
            companyId: `${companyId}`,
        };
        const { invoices }: any = await runQuery(
            INVOICES_BY_COMPANY_ID_LENGTH,
            variables
        );
        const filteredInvoices = invoices.filter((invoice: Invoice) => invoice.status !== 'draft');
        setInvoiceLength(filteredInvoices?.length ?? 0);
    }
};

const getInvoiceDownloadLink = async (invoiceId: string, runQuery: any) => {
    const variables = {
        id: `${invoiceId}`,
        format: 'pdf',
    };
    const response = await runQuery(
        INVOICE_DOWNLOAD_BY_INVOICE_ID,
        variables
    );
    const location = response.invoiceDownload.location;
    return location;
  };

export { getInvoices, getInvoicesLength, getInvoiceDownloadLink }