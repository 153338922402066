
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../Context/AuthContext/FirebaseAuthenticationWithContext";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArticleIcon from "@mui/icons-material/Article";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NavigationbarSuperAdmin from "./NavigationbarSuperAdmin";

import "./Navigationbar.css";

const NavigationbarAdmin = ({ handleDrawerClose }: any) => {
  const { userData } = useAuthContext();
  const history = useHistory();
  const changeRoute = (route: any) => {
    handleDrawerClose();
    history.push(route);
  };
  return (
    <>
      <Divider />
      <ListItem button onClick={() => changeRoute("/facturen")}>
        <ListItemIcon>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <ListItemText primary="Facturen" />
      </ListItem>

      <Divider />
      <ListItem button onClick={() => changeRoute("/creditnotes")}>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Creditnota's" />
      </ListItem>
      {userData?.role === "superadmin" && (
          <NavigationbarSuperAdmin
            handleDrawerClose={handleDrawerClose}
            userRole={userData?.role}
          />
      )}

      <Divider />
    </>
  );
};

export default NavigationbarAdmin;
