import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import Person from '@mui/icons-material/Person';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import "./LoginPage.css";
import Grid from '@mui/material/Grid';
import { userLogin } from '../../components/Models/UserModelContext'
import { useAuthContext } from '../../components/Context/AuthContext/FirebaseAuthenticationWithContext';
import { Redirect } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

const LoginPage = () => {
  const { trySignIn, userState } = useAuthContext();
  const [ErrorLoginMessage, setErrorLoginMessage] = useState<string>("")

  const [values, setValues] = useState<userLogin>({
    passwordOnchange: '',
    showPassword: false,
    usernameOnchange: ''
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const loginWithEnter = (event: any) => {
    if (event.key === 'Enter' && values.passwordOnchange && values.usernameOnchange) {
      handleLoginUser()
    }
  }

  const handleLoginUser = async () => {
    if (values.passwordOnchange && values.usernameOnchange) {
      const loginRes = await trySignIn(values.usernameOnchange, values.passwordOnchange);

      if (loginRes === "Foute gegevens") {
        setErrorLoginMessage("Gegevens zijn onjuist.")
        setValues({
          ...values, passwordOnchange: '', usernameOnchange: '',
        });
      }
    }

    else {
      setErrorLoginMessage("Vul alle velden in.")
      setValues({
        ...values, passwordOnchange: '', usernameOnchange: '',
      });
    }
  };

  if (userState) {
    const path = localStorage.getItem('requestedPath') || '/';
    return (<Redirect to={path}></Redirect>)
  }

  return (
    <div className='loginpagediv'>
      {
        localStorage.getItem('authUser') ? <div className='fullloadingscreenparent'>
          <div className='fullloadingscreen'>
            <div>
              <img src={process.env.PUBLIC_URL + "/Cogetix-Logo.png"} alt="Logo" width="220rem" height="80rem" />
            </div>
            <CircularProgress color="secondary" />
          </div>
        </div> :
          <Grid className='loginpageclass' component={Paper} elevation={4}>
            <div>
              <div>
                <img src={process.env.PUBLIC_URL + "/Cogetix-Logo.png"} alt="Logo" width="220rem" height="80rem" />
              </div>
              <div>
                {
                  ErrorLoginMessage ?
                    (<div>
                      <p className='ErrorMessage'>{ErrorLoginMessage}</p>
                    </div>)
                    :
                    (<div>

                    </div>)
                }
              </div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="blackcoloredinput">
                      <InputLabel htmlFor="outlined-adornment-user" className='labelinput' required>Gebruikersnaam</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-user"
                        type='text'
                        value={values.usernameOnchange}
                        onChange={handleChange('usernameOnchange')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              disabled
                              edge="end"
                            >
                              <Person />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Gebruikersnaam"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="blackcoloredinput">
                      <InputLabel htmlFor="outlined-adornment-password" className='labelinput' required>Wachtwoord</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.passwordOnchange}
                        onChange={handleChange('passwordOnchange')}
                        onKeyDown={loginWithEnter}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Wachtwoord"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} className="googleloginbutton">
                    <Button variant="outlined" type="submit" className="blackColoredButton" onClick={handleLoginUser} endIcon={<ArrowCircleRightIcon />}>
                      Inloggen
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>

          </Grid>
      }
    </div>
  )
}

export default LoginPage


