import { useAuthContext } from "../Context/AuthContext/FirebaseAuthenticationWithContext";
import Navigationbar from "./Navigationbar";

const Navigation = (props: any) => {
  const { userState } = useAuthContext();

  return userState ? (
    <>
      <Navigationbar>{props.children}</Navigationbar>
    </>
  ) : (
    <div>{props.children}</div>
  );
};

export default Navigation;
