import { createContext } from 'react';

export const CrmContext = createContext<any>({
    runQuery: (response: string) => response,
    getAllFirebaseUsers: (response: string) => response,
    createFirebaseUser: (response: string) => response,
    updateFirebaseUser: (response: string) => response,
    deleteFirebaseUser:  (response: string) => response,
    updateTeamleaderContact: (response: string) => response,
    updateTeamleaderCompany: (response: string) => response,
});