import React, { useEffect, useState } from 'react'
import { GetActionHandlerUrl } from './GetActionHandlerUrl';
import { getAuth } from "firebase/auth";
import { handleResetPassword, passwordResetConfirm } from './handleResetPassword'
import { Redirect } from 'react-router-dom';
import { ResetVerifyPassword, userResetPassword } from '../../components/Models/UserModelContext';
import PasswordChecklist from "react-password-checklist"
import './PasswordResetPage.css'
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import BlockIcon from '@mui/icons-material/Block';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const PasswordResetPage = () => {
  const [SucceedMessage, setSucceedMessage] = useState<string>()
  const [VerifyResetCode, setVerifyResetCode] = useState<ResetVerifyPassword>()
  const [resetSuccesfull, setresetSuccesfull] = useState<string>("")
  const [firstPassword, setfirstPassword] = useState<string>("")
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [passwordConfirm, setpasswordConfirm] = useState<string>("")
  const [seeResetForm, setseeResetForm] = useState(false)
  const [wrongResetUrl, setwrongResetUrl] = useState(false)

  const [values, setValues] = useState<userResetPassword>({
    passwordOnchange: '',
    showPassword: false,
    showPasswordConfirm: false,
    passwordConfirmOnchange: ''
  });
  const auth = getAuth();

  const handleActionUrl = async () => {
    let actionSucceed = await GetActionHandlerUrl();
    if (actionSucceed?.succeeded && actionSucceed?.mode == "resetPassword" && actionSucceed?.actionCode) {
      await resetPasswordHandling(actionSucceed.actionCode)
    }

    else if (!actionSucceed?.succeeded) {
      setwrongResetUrl(true)
    }
  }

  const resetPasswordHandling = async (actionCode: string) => {
    let verifiedresetcode: ResetVerifyPassword = await handleResetPassword(auth, actionCode)

    if (verifiedresetcode?.succeeded && verifiedresetcode?.actionCode && verifiedresetcode?.email) {
      setVerifyResetCode(verifiedresetcode)
      setseeResetForm(true);
    }

    else {
      setSucceedMessage(verifiedresetcode.message);
      setseeResetForm(false);
    }
  }

  useEffect(() => {
    handleActionUrl()
  }, [])

  const handlechangepassword = async () => {
    if (VerifyResetCode?.actionCode) {
      if (firstPassword === passwordConfirm && firstPassword && passwordConfirm) {
        setseeResetForm(false)
        let resetsuccesfully = await passwordResetConfirm(VerifyResetCode?.actionCode, auth, firstPassword);
        if (resetsuccesfully) {
          setresetSuccesfull("Wachtwoord succesvol gereset")
        }

        else {
          setresetSuccesfull("Er is iets fout gebeurd, probeer opnieuw of contacteer de beheerder.")
        }
      }
      else {
        setresetSuccesfull("Vul alles correct in.")
      }
    }
  }


  const handlepassword = async (event: any) => {
    setfirstPassword(event.target.value)
  }

  const handlepasswordConfirm = async (event: any) => {
    setpasswordConfirm(event.target.value)
  }

  const handleClickShowPassword = (passwordchoice: string) => {

    if (passwordchoice == "firstPassword") {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    }

    if (passwordchoice == "confirmPassword") {
      setValues({
        ...values,
        showPasswordConfirm: !values.showPasswordConfirm,
      });
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event: any) => {
    event.preventDefault();
  };

  const goLoginPage = () => {
    window.location.href = "/login";
  }

  return (
    <>
      {
        wrongResetUrl && <div>
          <Redirect to="/login" />
        </div>
      }
      {
        seeResetForm ? (<div>
          <div style={{ marginTop: '3rem' }}>
            <img src={process.env.PUBLIC_URL + "/Cogetix-Logo.png"} alt="Logo" width="220rem" height="80rem" />
          </div>
          <h2>Wijzig je wachtwoord</h2>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="blackcoloredinput">
                  <InputLabel htmlFor="outlined-adornment-password" required>Wachtwoord</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={firstPassword}
                    onChange={handlepassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword("firstPassword")}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Wachtwoord"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="blackcoloredinput">
                  <InputLabel htmlFor="outlined-adornment-passwordconfirm" required>Wachtwoord bevestigen</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-passwordconfirm"
                    type={values.showPasswordConfirm ? 'text' : 'password'}
                    value={passwordConfirm}
                    onChange={handlepasswordConfirm}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword("confirmPassword")}
                          onMouseDown={handleMouseDownPasswordConfirm}
                          edge="end"
                        >
                          {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Wachtwoord bevestigen"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {
                  disableBtn ? (<div>
                    <Button variant="outlined" disabled id='btnChangePassword' type="submit" className="blackColoredButtonDisabled" endIcon={<BlockIcon />}>
                      Wachtwoord wijzigen
                    </Button>
                  </div>
                  ) : (
                    <div>
                      <Button variant="outlined" id='btnChangePassword' type="submit" className="blackColoredButton" onClick={handlechangepassword} endIcon={<ArrowCircleRightIcon />}>
                        Wachtwoord wijzigen
                      </Button>
                    </div>
                  )
                }
              </Grid>
            </Grid>
          </Box>
          <div className='passwordchecklist'>
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={12}
              value={firstPassword}
              valueAgain={passwordConfirm}
              onChange={(isValid): any => { isValid ? setDisableBtn(false) : setDisableBtn(true) }}
              messages={{
                minLength: "Je wachtwoord bevat minstens 12 karakters.",
                specialChar: "Je wachtwoord bevat speciale karakters.",
                number: "Je wachtwoord bevat getallen",
                capital: "Je wachtwoord bevat hoofdletters.",
                match: "Je wachtwoorden komen overeen.",
              }}
            />
          </div>
        </div>
        ) : (<div>
          {
            SucceedMessage && <div>
              <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
                <Snackbar open={true} className="Alertmessages">
                  <Alert severity="error" sx={{ width: '100%' }} >
                    {SucceedMessage}
                  </Alert>
                </Snackbar>
              </div>
              <img style={{ marginTop: '6rem' }} src={process.env.PUBLIC_URL + "/Cogetix-Logo.png"} alt="Logo" width="220rem" height="80rem" />
              <br />
              <Button variant="outlined" id='btnChangePassword' type="submit" className="blackColoredButton" onClick={goLoginPage} endIcon={<ArrowCircleRightIcon />}>
                Ga naar loginpagina
              </Button>
            </div>
          }
        </div>
        )
      }
      {
        resetSuccesfull && !wrongResetUrl ? (<div>
          <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
            <Snackbar open={true} className="Alertmessages">
              <Alert severity="success" sx={{ width: '100%' }} >
                {resetSuccesfull}
              </Alert>
            </Snackbar>
          </div>
          <img style={{ marginTop: '6rem' }} src={process.env.PUBLIC_URL + "/Cogetix-Logo.png"} alt="Logo" width="220rem" height="80rem" />
          <br />
          <Button variant="outlined" id='btnChangePassword' type="submit" className="blackColoredButton" onClick={goLoginPage} endIcon={<ArrowCircleRightIcon />}>
            Ga naar loginpagina
          </Button>
        </div>) : (<></>)
      }
    </>
  )
}

export default PasswordResetPage;