import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { ContactData } from "../../components/Models/TeamleaderModels";
import { useCrmContext } from "../../components/Context/CrmContext/CrmRequestsContext";
import CloseModalButton from "../../components/Helpers/exitModalButton";
import { getLangFromAbrv } from "../../components/Helpers/userLangChecker";

interface ContactModalProps {
  contactData: ContactData;
  idToUpdateUser: string;
  getEmployeeData: () => void;
}

const ContactModal = ({ contactData, idToUpdateUser, getEmployeeData}: ContactModalProps) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<ContactData>(contactData);
  const { updateTeamleaderContact } = useCrmContext();
  
  const handleOpen = () => {
    const transformedContactData = {
      ...contactData,
      language: getLangFromAbrv(contactData.language)
    };
    setOpen(true);
    setFormData(transformedContactData);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    if (id === "email") {
      const updatedEmails = formData.emails.map((email, index) =>
        index === 0 ? { ...email, email: value } : email
      );
      setFormData((prevState) => ({
        ...prevState,
        emails: updatedEmails,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormData((prevState) => ({
      ...prevState,
      language: event.target.value as string,
    }));
  };

  const handleSave = async () => {
    await updateTeamleaderContact(formData, idToUpdateUser);
    getEmployeeData();
    handleClose();
  };

  return (
    <div>
      <Button
        style={{
          color: "var(--cogetixRed)",
          border: "none",
          background: "transparent",
        }}
        onClick={() => {
          handleOpen();
        }}
      >
        <EditRoundedIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          className="modalContact"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <CloseModalButton handleClose={handleClose} />
          <h3>Medewerker aanpassen</h3>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Voornaam"
                id="first_name"
                value={formData.first_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Achternaam"
                id="last_name"
                value={formData.last_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Email Type"
                  id="emailType"
                  value={formData.emails[0].type}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Email"
                id="email"
                type="email"
                value={formData.emails[0].email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="geboortedatum"
                id="birthdate"
                value={formData.birthdate}
                type="date"
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.marketing_mails_consent}
                    id="marketing_mails_consent"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label="Toestemming voor marketing e-mails."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="language-label">Taal</InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  value={formData.language}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="Nederlands">Nederlands</MenuItem>
                  <MenuItem value="Français">Français</MenuItem>
                  <MenuItem value="English">English</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                onClick={() => {
                  handleSave();
                }}
                style={{ marginTop: "1rem", backgroundColor: "var(--cogetixRed)" }}
              >
                <CheckRoundedIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactModal;
